import React, {FC, useState} from "react";
import {Box} from "theme-ui";
import Image from "next/image";

interface Chat {
  chatLink: {
    value: {
      data: {
        title: string
        icon: string
        link: string
      }
    }
  }
}

interface Props {
  ChatIcon: string
  chats: Array<Chat>
}

export const Chat:FC<Props> = (props) => {
  const { chats } = props
  const [show, setShow] = useState(false);
  const [chatIsOpen, setChatIsOpen] = useState(false)

  const openModal = () => {
    if(show) {
      setChatIsOpen(true)
    }
    else setChatIsOpen(false)
    setShow(!show)
  }

  const drawChats = () => {
    return chats?.map?.((chat, index) => {
      if (chat.chatLink && chat.chatLink.value){

        return (
            <a
                className={[(show ? 'chat-link-show': ''),(chatIsOpen ?'chat-link-hide':''), 'chat-link'].join(' ')}
                key={index}
                style={{  backgroundImage: `url(${chat.chatLink.value.data.icon})` }}
                href={chat.chatLink.value.data.link}
            />
        )
      }
    })
  }

  return (
      <Box className={'chat-container'} style={{position: "fixed", bottom: '40px', right: '40px', zIndex: 1000}}>
        <Box className={'d-flex align-items-center'}>
          <Box p={10} className={'rounded-2 text-secondary'} bg={'rgba(0, 0, 0, 0.7)'} sx={{fontSize: '16px', borderRadius: '4px'}}>Задайте вопрос, мы онлайн</Box>
          <svg height="15" width="15">
            <polygon
                points="0,0 10,7.5 0,15"
                fill="rgba(0, 0, 0, 0.7)"/>
          </svg>
        </Box>
        <Box
            onClick={openModal}
            className={['chat-btn', 'text-primary', (!show && 'chat-btn-anim')].join(' ')}
            bg={'rgb(255, 237, 220)'}
        >
          {show ?
              <i className="bi bi-x-lg" /> :
              <Image src={props.ChatIcon} alt={'chat'} width={100} height={100} style={{objectFit: 'contain', width: '60px', height: '60px'}} loading={'lazy'} />
          }
        </Box>
        <Box className={['chat-link-container', (show && 'chat-link-container-show')].join(' ')}>
          {drawChats()}
        </Box>
      </Box>
  )
}
